.nav-container {
  width: 100vw;
  /* height: 10vh; */
  height: 8vh;
  display: flex;
  align-items: center;
  /* background-color: #3d2424; */
  /* padding: 16px 0; */
}

.logos-container {
  height: inherit;
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}

/* .logo-container {
  align-self: start;
} */

.logo-container img {
  height: inherit;
  cursor: pointer;
}

.logos {
  margin-left: auto;
}
.logos-list {
  list-style: none;
  display: flex;
  margin-left: auto;
}

.logos-list li {
  padding: 0 6px;
  cursor: pointer;
  color: #fdece2;
}

.logos-list li a svg {
  filter: drop-shadow(3px 5px 2px rgb(0 0 0 / 0.4));
}

.logos-list li a svg:hover {
  color: #dd4f45;
}

.logos-list li a {
  list-style: none;
  color: #fdece2;
}

.button {
  color: #000000;
  margin: 4px 32px 4px 16px;
  padding: 6px 12px;
  background-color: #dd4f45;
  font-family: "RocaTwo", sans-serif;

  border: none;
  border-radius: 6px;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px,
    rgba(0, 0, 0, 0.22) 0px 15px 12px;
  text-align: center;
  cursor: pointer;
}

.button:hover {
  background-color: #000000;
  color: #fdece2;
}

/* button.popupBtn {
  margin-left: auto;
} */

@media only screen and (max-width: 768px) {
  /* For mobile phones: */
  .button {
    margin: 4px 16px;
  }

  .logos-list li {
    padding: 2px;
  }

  .logos-list li a svg {
    height: 15px;
  }
}
