.word-wrapper {
  font-size: 156px;
}

.header1 {
  color: #63be7d;
}

/* .header2,
.header1,
.header3 {
  color: #f6584d;
} */

.header2,
.header4,
.header5,
.header6 {
  color: #f6584d;
}

.header1,
.header2 {
  font-family: "Heebo";
}

.header3,
.header6 {
  font-family: "RocaTwo";
}

.header3 {
  color: #fdece2;
}

.headers {
  padding: 0 32px;
}

.replayBtn {
  margin-top: 100px;
}

@media only screen and (max-width: 768px) {
  /* For mobile phones: */
  .word-wrapper {
    font-size: 52px;
  }

  .headers {
    padding: 0 12px;
  }
}
