img {
  height: 225px;
  transition: all 250ms;
}

/* .text-container {
  display: flex;
  justify-content: center;
} */

.slideshow-container {
  width: 100%;
}

.last-slide {
  display: flex;
  justify-content: center;
  width: 100%;
}

.text {
  display: flex;
  flex-direction: column;
}

.show {
  display: flex;
  flex-direction: column;
  margin: 0;
  width: 100%;
}
.hide {
  display: none;
}

@media only screen and (max-width: 768px) {
  /* For mobile phones: */

  img {
    height: 200px;
  }
}
