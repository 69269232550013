@font-face {
  font-family: "RocaTwo";
  src: local("FontsFree-Net-Roca-Two-Bold"),
    url("./Fonts/FontsFree-Net-Roca-Two-Bold.ttf");
}

html {
  overflow-y: hidden;
  overflow-x: hidden;
}

#root {
  background: linear-gradient(
    90deg,
    rgba(55, 32, 32, 1) 0%,
    rgba(49, 29, 29, 1) 48%,
    rgba(61, 36, 36, 1) 100%
  );
}

.container {
  width: 100vw;
  min-height: 100vh;

  /* background-color: #3d2424; */
  display: flex;
  align-items: center;
  justify-content: center;
  /* hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none;
  scrollbar-width: none;
}

/* hide scrollbar for chrome, safari and opera */
.container::-webkit-scrollbar {
  display: none;
}

.text-container h1 {
  margin: 0px 0;
  height: 124px;
}

@media only screen and (max-width: 768px) {
  /* For mobile phones: */

  .text-container h1 {
    height: 42px;
  }
}
