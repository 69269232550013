.logo-container {
  display: flex;
  flex-direction: column-reverse;
}

.u-container {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: 40px;
}

/* .line-container {
  position: absolute;
} */
