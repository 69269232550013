.socials-container {
  position: relative;
  top: 42px;
  padding: 8px 16px;
}

.sheild {
  filter: drop-shadow(3px 5px 2px rgb(0 0 0 / 0.4));
  cursor: pointer;
  overflow: hidden;
  width: 88px;
}

.socials {
  display: flex;
  justify-content: center;
}

.socials-icons {
  font-size: 14px;
}

.socials-list {
  list-style: none;
  display: flex;
  margin: 4px 0;
  padding: 0;
}

.socials-list li {
  padding: 0 2px;
  cursor: pointer;
  color: #fdece2;
}

.socials-list li:hover {
  color: white;
}

.socials-list li a {
  list-style: none;
  color: #fdece2;
}

.socials-list li a svg {
  filter: drop-shadow(3px 5px 2px rgb(0 0 0 / 0.4));
}

.socials-list li a:hover {
  color: #f6584d;
  scale: 1.1;
}

/* .socials {
  position: relative;
  top: 16px;
} */

.shield-container {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* .shield-container p {
  font-family: "Rocatwo", sans-serif;
  color: #fdece2;

  font-size: 14px;
  margin: 0;
  padding: 0 2px;
  position: relative;
  bottom: 55px;
  height: auto;
  width: 80%;
  text-align: center;
} */

.popupText {
  color: #fdece2;
  z-index: 2;
  margin: 0;
  position: relative;
  text-align: center;
  bottom: 60px;
  font-size: 12px;
  font-family: "rocaTwo", sans-serif;
  cursor: pointer;
  display: flex;
  flex-direction: column;
}

.arrow {
  color: #c5463e;
  font-size: 18px;
}

.popupText button {
  cursor: pointer;
  margin: 0;
  padding: 0;
}

.arrow:hover {
  color: #f6584d;
  scale: 1.1;
}

@media only screen and (max-width: 768px) {
  /* For mobile phones: */
  .socials-container {
    top: 28px;
    padding: 4px 8px;
  }

  .sheild {
    width: 56px;
  }

  .popupText {
    font-size: 9px;
    width: 90%;
    bottom: 48px;
  }
  .arrow {
    font-size: 12px;
  }

  .socials-icons {
    font-size: 8px;
  }
}
