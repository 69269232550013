.logo-animation {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.logo-ul-container {
  overflow: hidden;
  padding: 16px 8px;
}

.motion-ul {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
}

.motion-ul li {
  margin: 0 4px;
}

.motion-ul li svg {
  filter: drop-shadow(3px 5px 2px rgb(0 0 0 / 0.4));
  height: 200px;
}

.underline-container {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.underline {
  width: 100%;
  height: 25px;
  background-color: #f6584d;
  filter: drop-shadow(3px 5px 2px rgb(0 0 0 / 0.4));
  z-index: 2;
  border-radius: 2px;
}

/* .header-container {
  position: relative;
  bottom: 30px;
} */

.header-container h2 {
  font-family: "Heebo", sans-serif;
  font-size: 28px;
  letter-spacing: 22px;
  color: #fdece2;
  filter: drop-shadow(3px 5px 2px rgb(0 0 0 / 0.4));
  margin: 0;
}

@media only screen and (max-width: 768px) {
  /* For mobile phones: */
  .logo-animation {
    width: 100%;
  }

  .logo-ul-container {
    padding: 8px 0 4px 0;
  }

  .motion-ul li svg {
    height: 90px;
  }

  .underline {
    height: 15px;
  }

  /* .header-container {
    bottom: 18px;
  } */

  .header-container h2 {
    font-size: 18px;
    letter-spacing: 8px;
  }
}
